@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body
{
    background-color: #fff;
    width: 100%;
    height: 100vh;
    font-family: 'Raleway', sans-serif;
}

.areaMain
{
    width: 100%;
    height: 100%;
}

.areaVideo
{
    width: calc(80% - 1px);
    height: 100vh;
    border-right: 1px solid #d1d2d4;
    float: left;
    display: flex;
  	align-items: center;
    justify-content: center; 
    background-image: url(/static/media/back.aceebd22.png);
    background-size: cover;
    background-position: center;       
}

.centerElement
{
    width: 80%;
}

.logo
{
    width:400px;
    height: auto;
}


#logos
{
    margin-bottom:20px;
    width: 100%;
    text-align: center;
}

#vid
{
	position: relative;
	padding-bottom: 56.25%; /*panorámico*/
    height: 0;
    margin-top: 25px;
}

#vid iframe
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.areaChat
{
    width: 20%;
    height: 100vh;
    float: right;
    position: relative;
    margin: 0;
    padding: 0;
}

.areaChat iframe
{
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
}

@media screen and (max-width:1500px) {

    .areaChat
    {
        width: 30%;
    }

    .areaVideo
    {
        width: calc(70% - 1px);
    }

    .centerElement
    {
        width: 90%;
    }

}    

@media screen and (max-width:600px) {

    .areaVideo
    {
        width: 100%;
        height: 40vh;
        display: block;
        border: none;
    }

    #logos
    {
        display: none;
    }

    .centerElement
    {
        width: auto;
        height: 100%;
        background-color: #eef1f4;
    }

    #vid
    {
        position: relative;
        padding-bottom: 0%; /*panorámico*/
        padding-top: 0px;
        height: 100%;
        margin-top: 0;
    }

    #vid iframe
    {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    
    .areaChat
    {
        width: 100%;
        height: 60vh;
    }

}

